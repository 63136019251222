import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import { searchBox, hits, pagination } from 'instantsearch.js/es/widgets';

// rm ./dist/*.js && parcel build src/app2023011200.js -o app2023011201.min.js

const searchClient = algoliasearch('41V3NYWBAD', 'dd89cbbeec8bc4a4425c77e02b5214e9')

const search = instantsearch({
  indexName: 'findschool_prod',
  searchClient,
});

const searchDiv = document.getElementById('search-div');

const hideSearchResults = () => {
  searchDiv.classList.add('is-hidden');
};

const showSearchResults = () => {
  searchDiv.classList.remove('is-hidden');
};

const hitTemplate = function (hit) {
  const searchInput = document.querySelectorAll('#search-searchbar input')[0].value

  if (searchInput == '') {
    hideSearchResults();
    return;
  } else {
    showSearchResults();
    let url = `${hit.url}#${hit.anchor}`;

    const title = hit._highlightResult.title.value;

    let breadcrumbs = '';
    if (hit._highlightResult.headings) {
      breadcrumbs = hit._highlightResult.headings.map(match => {
        return `<span class="post-breadcrumb">${match.value.split(' ').slice(0, 10).join(' ')}</span>`
      }).join(' > ')
    }

    const content = hit._highlightResult.content.value.split(' ').slice(0, 20).join(' ');

    return `
      <div class="card my-2">
        <div class="card-content">
          <div class="media">
            <div class="media-left">
              <figure class="image is-24x24">
                <img src="/assets/images/logo.png" alt="Placeholder image">
              </figure>
            </div>
            <div class="media-content">
              <h2 class="title is-4">
                <a class="has-text-link" href="${url}">${title}</a>
              </h2>
              <!--
                <p class="subtitle is-6">
                  <a href="${url}" class="has-text-black">${breadcrumbs}</a>
                </p>
              -->
              <div class="content is-5">${content} ...</div>
            </div>
          </div>
        </div>
      </div>
  `;
  }
}

const emptyHitTemplate = function (hit) {
  showSearchResults();
  return `
    <div class="post-item">
        Ничего не найдено по запросу <q>${hit.query}</q>
    </div>
  `;
}

const addStyleToPagination = () => {
  const ns = document.querySelectorAll('.pagination-list')[0];
  if (!ns) return;

  ns.childNodes.forEach(e => {

    if (e.classList.contains('is-current')) {
      e.childNodes.forEach(e => {
        e.classList.add('is-current')
      });
    } else {
      e.childNodes.forEach(e => {
        e.classList.remove('is-current')
      });
    }

    if (e.classList.contains('is-disabled')) {
      e.childNodes.forEach(e => {
        e.classList.add('is-disabled')
      });
    } else {
      e.childNodes.forEach(e => {
        e.classList.remove('is-disabled')
      });
    }
  });
}

search.addWidgets([
  searchBox({
    container: '#search-searchbar',
    placeholder: 'Что и где вы ищете? Например, школу Ваке, садик Сабуртало или шахматы Тбилиси',
    poweredBy: false, // This is required if you're on the free Community plan
    showLoadingIndicator: false,
    autofocus: false,
    showReset: false,
    showSubmit: false,
    cssClasses: {
      input: 'input',
    },
  }),

  hits({
    container: '#search-hits',
    templates: {
      item: hitTemplate,
      empty: emptyHitTemplate,
    }
  }),

  pagination({
    container: '#search-pagination',
    // padding: 2,
    cssClasses: {
      list: 'pagination-list',
      selectedItem: "is-current",
      link: "pagination-link",
      disabledItem: "is-disabled",
    },
  }),
]);

search.start();
search.on('render', () => {
  // Do something on render
  addStyleToPagination();
});
